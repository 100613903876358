import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { rhythm, scale } from "../utils/typography"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header
  const data = useStaticQuery(graphql`
    query {
      file(absolutePath: { regex: "/icons8-heart-outline-48.png/" }) {
        childImageSharp {
          fixed(width: 25, height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
        }
      }
    }
  `)
  if (location.pathname === rootPath) {
    header = (
      <h1
        // css={css`
        //   color: red;
        //   margin-bottom: ${rhythm(1.5)};
        //   margin-top: 0;
        //   font-size: ${scale(1.5)["fontSize"]};
        //   line-height: ${scale(1.5)["lineHeight"]};
        // `}
        css={{
          ...scale(1.5),
          marginBottom: rhythm(1.5),
          marginTop: 0,
          color: "#e53982",
          "@media(max-width: 600px)": {
            // fontSize: "2rem",
            // lineHeight: 1.2,
            // ...scale(1),
          },
        }}
        // style={{
        //   ...scale(1.5),
        //   marginBottom: rhythm(1.5),
        //   marginTop: 0,
        //   color: "#e53982",
        // }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )
  } else {
    header = (
      <h3
        style={{
          fontFamily: `Montserrat, sans-serif`,
          marginTop: 0,
          color: "#e53982",
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }
  const { author } = data.site.siteMetadata

  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <header>{header}</header>
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()}, Built with
        {` `}
        <Image
          fixed={data.file.childImageSharp.fixed}
          alt={author.name}
          style={{ height: 0, width: 20, paddingTop: 15 }}
        />
        {/*<a href="https://www.gatsbyjs.org">Gatsby</a>*/}
      </footer>
    </div>
  )
}

export default Layout
